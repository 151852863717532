import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import { AssetData } from '../data/assetData';
import { useAssetData, useDefault } from '../helpers/hooks';
import { FullscreenHudElement, FullscreenHudElementProps } from './fullscreenHudElement';

export interface FullscreenHudProps {
  /**
   * Show or hides this component.
   */
  visible?: boolean;
  /**
   * The image asset to be used as background of the screen.
   */
  background?: AssetData;
  /**
   * The SVG viewport coordinates for the current SVG fragment.
   */
  viewBox?: string;
  /**
   * Children, should be of the type FullscreenHudElement.
   */
  children?: ReactNode;
}

export function FullscreenHud(props: FullscreenHudProps) {
  const background = useAssetData(props.background);
  const viewBox = useDefault(props.viewBox, '0 0 1920 1080');
  const [children, setChildren] = useState<ReactElement[]>([]);
  const [highlight, setHighlight] = useState<string>(null);
  const [highlightEnabled, setHighlightEnabled] = useState(false);

  function onMouseEnter(highlight: AssetData) {
    setHighlight(highlight.data);
    setHighlightEnabled(true);
  }

  function onMouseLeave() {
    setHighlightEnabled(false);
  }

  useEffect(() => setChildren(React
    .Children
    .toArray(props.children)
    .filter((child: ReactElement) => child.type === FullscreenHudElement)
    .map((child: ReactElement<FullscreenHudElementProps>) => {
      const realChild = child.props.children as ReactElement<SVGElement>;

      return <realChild.type
        {...realChild.props}
        key={child.key}
        onMouseEnter={() => onMouseEnter(child.props.highlight)}
        onMouseLeave={onMouseLeave}
        onClick={child.props.onClick} />;
    })), [props.children]);

  return (props.visible !== false
    ? <div className="layer fullscreen-hud">
      {background && <img className="layer" src={background} />}
      <img className={`layer highlight ${highlightEnabled ? 'on' : ''}`} src={highlight} />
      <svg viewBox={viewBox} className="layer">
        {children}
      </svg>
    </div>
    : <div></div>
  )
}