import { Notifier } from '../helpers/notifier';
import { AssetData } from '../data/assetData';

export interface AudioControlMap {
  volumeEnableChanged: (target: AudioControl) => void;
}

export default class AudioControl extends Notifier<AudioControlMap> {
  private player: HTMLAudioElement;
  private _audioEnabled: boolean;

  get audioEnabled() { return this._audioEnabled; }

  setPlayer(element: HTMLAudioElement) {
    this.player = element;
    this.player.controls = false;
    this.player.loop = true;
  }

  setAudio(audio: AssetData) {
    if (!this.player) return Promise.resolve();

    return new Promise((resolve, reject) => {
      this.player.addEventListener('canplay', () => {
        this.player.play();
        resolve();
      }, { once: true });

      this.player.addEventListener('error', () => {
        reject();
      }, { once: true });

      this.player.src = audio.data;
      this._audioEnabled = true;
      this.dispatchEvent('volumeEnableChanged', this);
    });
  }

  toggleAudioEnabled() {
    if (this._audioEnabled) {
      this.disableAudio();
    }
    else {
      this.enableAudio();
    }
  }

  disableAudio() {
    if (!this.player) return;

    this._audioEnabled = false;
    this.player.pause();
    this.dispatchEvent('volumeEnableChanged', this);
  }

  enableAudio() {
    if (!this.player) return;

    this._audioEnabled = true;
    this.player.play();
    this.dispatchEvent('volumeEnableChanged', this);
  }
}

export const audioControl = new AudioControl();