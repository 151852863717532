import { Notifier } from '../helpers/notifier';
import { AssetData } from '../data/assetData';
import { Loader, LoaderMap } from './loader';

/**
 * Provides a way to load assets from a server and serialize them into a string.
 */
export class BasicAssetLoader<TData extends AssetData> extends Notifier<LoaderMap<AssetData, BasicAssetLoader<TData>>> implements Loader<TData> {
  readonly asset: TData;
  private readonly request: XMLHttpRequest;
  private _loaded = 0;
  private _total = 0;

  /**
   * Creates a helper capable to load an asset from server.
   * 
   * @param asset the asset information to make the download.
   */
  constructor(asset: TData) {
    super();
    this.asset = asset;
    this.request = new XMLHttpRequest();
  }

  get loaded() { return this._loaded; }
  get total() { return this._total; }
  get progress() { return this._loaded / this._total; }

  async load() {
    return new Promise<TData>((resolve, reject) => {
      this.request.open('GET', this.asset.dataUrl);
      this.request.responseType = 'blob';

      this.request.addEventListener('loadstart', () => {
        this._loaded = 0;
        this._total = this.asset.estimatedSize || 0;
      });

      this.request.addEventListener('progress', e => {
        this._loaded = e.loaded;
        this._total = e.total;

        this.dispatchEvent('progress', this, this);
      });

      this.request.addEventListener('load', e => {
        this._loaded = this._total = e.total;
        const data = URL.createObjectURL(this.request.response);
        this.asset.setData(data);

        resolve(this.asset);

        this.dispatchEvent('load', this.asset, this);
      });

      this.request.addEventListener('error', () => {
        reject(this.request.responseText);

        this.dispatchEvent('error', this.request.responseText, this.asset, this);
      });

      this.request.send();
    });
  }

  abort() {
    this.request.abort();
  }
}