import { AssetType, AssetData } from '../data/assetData';
import { Loader, LoaderConstructor } from './loader';
import { BasicAssetLoader } from './basicAssetLoader';
import { Dictionary } from '../helpers/types';

export class LoaderFactory {
  private readonly registry: Dictionary<LoaderConstructor<any>> = {};

  register<TData extends AssetData>(type: AssetType, loaderCreator: LoaderConstructor<TData>) {
    this.registry[type] = loaderCreator;
  }

  getLoader<TData extends AssetData>(asset: TData): Loader<TData> {
    const creator = this.registry[asset.type];

    if (!creator) throw new Error(`There is no loader register to type ${asset.type}`);

    return new creator(asset);
  }
}

export const loaderFactory = new LoaderFactory();

loaderFactory.register('audio', BasicAssetLoader);
loaderFactory.register('image', BasicAssetLoader);
loaderFactory.register('video', BasicAssetLoader);
loaderFactory.register('frameSequence', BasicAssetLoader);