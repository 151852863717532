import { AssetData, AssetType } from './assetData';
import { FrameSequenceData } from './frameSequenceData';

/**
 * Created a container to hold an asset data.
 * 
 * @param dataUrl The URL to this asset on server.
 * @param estimatedSize An estimation in bytes about the asset size.
 * @param type The type of the asset.
 * @param mime The asset mime type.
 * @param chunkName The name of the chunk to which this asset belongs to.
 * @param priority The priority of the asset, assets with lower values are loaded first.
 */
export function createAssetData(dataUrl: string, estimatedSize: number, type: AssetType, mime: string, chunkName: string, priority: number) {
  return new AssetData(dataUrl, estimatedSize, type, mime, chunkName, priority);
}

/**
 * Created a container to hold data of an image.
 * 
 * @param dataUrl The URL to this asset on server.
 * @param estimatedSize An estimation in bytes about the asset size.
 * @param chunkName The name of the chunk to which this asset belongs to.
 * @param priority The priority of the asset, assets with lower values are loaded first.
 */
export function createImageData(dataUrl: string, estimatedSize: number, chunkName: string, priority: number = 0) {
  return new AssetData(dataUrl, estimatedSize, 'image', 'image/png', chunkName, priority);
}

/**
 * Created a container to hold data of a mesh.
 * 
 * @param dataUrl The URL to this asset on server.
 * @param estimatedSize An estimation in bytes about the asset size.
 * @param chunkName The name of the chunk to which this asset belongs to.
 * @param priority The priority of the asset, assets with lower values are loaded first.
 */
export function createMeshData(dataUrl: string, estimatedSize: number, chunkName: string, priority: number = 0) {
  return new AssetData(dataUrl, estimatedSize, 'mesh', 'application/octet-stream', chunkName, priority);
}

/**
 * Created a container to hold a video data.
 * 
 * @param dataUrl The URL to this asset on server.
 * @param estimatedSize An estimation in bytes about the asset size.
 * @param chunkName The name of the chunk to which this asset belongs to.
 * @param priority The priority of the asset, assets with lower values are loaded first.
 */
export function createVideoData(dataUrl: string, estimatedSize: number, chunkName: string, priority: number = 0) {
  return new AssetData(dataUrl, estimatedSize, 'video', 'video/mp4', chunkName, priority);
}

/**
 * Created a container to hold a audio data.
 * 
 * @param dataUrl The URL to this asset on server.
 * @param estimatedSize An estimation in bytes about the asset size.
 * @param chunkName The name of the chunk to which this asset belongs to.
 * @param priority The priority of the asset, assets with lower values are loaded first.
 */
export function createAudioData(dataUrl: string, estimatedSize: number, chunkName: string, priority: number = 0) {
  return new AssetData(dataUrl, estimatedSize, 'audio', 'audio/mpeg', chunkName, priority);
}

/**
 * Created a container to hold a sequence of frames.
 * 
 * @param dataUrl The URL to this asset on server.
 * @param estimatedSize An estimation in bytes about the asset size.
 * @param chunkName The name of the chunk to which this asset belongs to.
 * @param priority The priority of the asset, assets with lower values are loaded first.
 */
export function createFrameSequenceData(dataUrl: string, estimatedSize: number, chunkName: string, frameCount: number, framePerSecond: number, priority: number = 0) {
  return new FrameSequenceData(dataUrl, estimatedSize, chunkName, frameCount, framePerSecond, priority);
}