import { Notifier } from "../helpers/notifier";
import { AssetData } from "../data/assetData";
import { ProgressInfo, Loader } from "./loader";
import { loaderFactory } from "./loaderFactory";
import { Dictionary, GenericFunc } from "../helpers/types";

export interface AssetMap {
  [assetName: string]: AssetData;
}

export interface ProgressTag {
  getLoaders: () => Loader<AssetData>[];
  readonly initialLoaded: number;
}

export interface ChunkLoaderEventMap {
  progress: (e: ProgressInfo, assetChanged: AssetData, target: ChunkLoader) => void;
  load: (asset: AssetMap, target: ChunkLoader) => void;
  error: (error: string, data: AssetMap, target: ChunkLoader) => void;
}

/**
 * A helper capable of download a large amount of assets respecting those assets priority
 * and providing detailed information about the progress of chunks of assets.
 */
export class ChunkLoader extends Notifier<ChunkLoaderEventMap> {
  private readonly assets: AssetMap;
  private readonly loadersQueue: Loader<AssetData>[] = [];
  private readonly loadersStarted: Loader<AssetData>[] = [];
  private readonly allLoaders: Loader<AssetData>[] = [];
  private readonly binds: Dictionary<GenericFunc>[] = [];
  private readonly chunkPriority: Dictionary<number> = {};
  private isLoadCalled = false;
  private _loaded = 0;
  private _total = 0;
  private _poolLimit: number;
  private lastLoaded = -1;

  /**
   * Creates a helper capable of download a large amount of assets.
   * @param assets A dictionary of assets.
   * @param poolLimit The maxumum number of assets to be download at the same time.
   */
  constructor(assets: AssetMap, poolLimit: number = 6) {
    super();

    this.assets = assets;
    this._poolLimit = poolLimit;
    this.createLoaders(assets);
    this.sortLoaders();
  }

  get loaded() { return this._loaded; }
  get total() { return this._total; }
  get progress() { return this._loaded / this._total; }
  get poolLimit() { return this._poolLimit; }

  private createLoaders(assets: AssetMap) {
    for (const assetName in assets) {
      const loader = loaderFactory.getLoader(assets[assetName])

      const e: Dictionary<GenericFunc> = this.binds[loader.asset.assetId] = {};

      loader.addEventListener('error', e.load = this.onItemError.bind(this));
      loader.addEventListener('progress', e.progress = this.onItemProgress.bind(this));
      loader.addEventListener('load', e.error = this.onItemLoad.bind(this));

      this.loadersQueue.push(loader);
      this.allLoaders.push(loader);
    }
  }

  private sortFunc(left: AssetData, right: AssetData) {
    const chunkLeft = this.chunkPriority[left.chunkName] || 0;
    const chunkRight = this.chunkPriority[right.chunkName] || 0;

    if (chunkLeft !== chunkRight) return chunkRight - chunkLeft; // greater is prioritize.

    return left.priority - right.priority;//lower is prioritize.
  }

  private sortLoaders() {
    this.loadersQueue.sort((l, r) => this.sortFunc(l.asset, r.asset));
  }

  private onItemLoad(_: AssetData, loader: Loader<AssetData>) {
    this.onItemProgress(undefined, loader);

    this.startNextLoader();
  }

  private onItemProgress(_: ProgressInfo, instigator: Loader<AssetData>) {
    let loaded = 0;
    let total = 0;

    for (const loader of this.allLoaders) {
      loaded += loader.loaded;
      total += loader.total;
    }

    if (this.lastLoaded >= loaded) return;
    this.lastLoaded = loaded;

    this.dispatchEvent('progress', { loaded, total, progress: loaded / total }, instigator.asset, this);
  }

  private onItemError(error: string, data: AssetData, target: Loader<AssetData>) {
    console.log('error objects => ', error, data, target);
    throw new Error('A item could not be downloaded');
  }

  private startNextLoader() {
    const loader = this.loadersQueue.shift();

    if (!loader) return;

    this.loadersStarted.push(loader);

    loader.load();
  }

  /**
   * Start to load all 
   */
  async load() {
    if (this.isLoadCalled) throw new Error('Load cannot be called more than once.');
    this.isLoadCalled = true;

    const allPromises = this.loadersQueue.map(l => l.asset.promise);

    for (let i = 0; i < this.poolLimit; i++) {
      this.startNextLoader();
    }

    return Promise
      .all(allPromises)
      .then((assets) => {
        this.dispatchEvent('load', this.assets, this);
        return assets;
      });
  }

  /**
   * Abort all
   */
  abort() {
    for (const loader of this.loadersQueue) {
      loader.abort();
    }
  }

  private getProgressFrom(loaders: Loader<AssetData>[]) {

    let loaded = 0;
    let total = 0;

    for (const loader of loaders) {
      loaded += loader.loaded;
      total += loader.total;
    }

    return { loaded, total, progress: loaded / total };
  }

  /**
   * Get progress information about all assets with priority lower than or equals to `maxPriority`.
   * 
   * @param maxPriority The inclusive limit of what assets should be included on progress.
   */
  getPriorityProgress(maxPriority: number): ProgressInfo {
    const loaders = this.allLoaders.filter(l => l.asset.priority <= maxPriority);

    return this.getProgressFrom(loaders);
  }

  /**
   * Get progress information about a checkpoint previusly created.
   * 
   * @param tag A tag object
   */
  getTagProgress(tag: ProgressTag): ProgressInfo {
    if (!tag) throw new Error('Tag is a required argument');

    const loaders = tag.getLoaders();
    const progress = this.getProgressFrom(loaders);

    const loaded = progress.loaded - tag.initialLoaded;
    const total = loaders.reduce((ac, i) => ac + i.total, 0) - tag.initialLoaded;

    return { loaded, total, progress: loaded / total };
  }

  /**
   * Get progress information about all assets belonging to the chunks with the names contained in `chunkNames`.
   * 
   * @param chunkNames An array of chunk names.
   */
  getChunksProgress(...chunkName: string[]): ProgressInfo {
    const loaders = this.allLoaders.filter(l => chunkName.indexOf(l.asset.chunkName) >= 0);

    return this.getProgressFrom(loaders);
  }

  /**
   * Set a higher priority to all assets belonging to the chunks with the names contained on `chunkNames`.
   * 
   * @param chunkNames An array of chunk names.
   */
  prioritizeChunks(...chunkNames: string[]): void {
    let max = 0;

    for (const key in this.chunkPriority) {
      const current = this.chunkPriority[key];
      if (current > max) max = current;
    }

    max++;

    for (const chunkName of chunkNames) {
      this.chunkPriority[chunkName] = max;
    }

    this.sortLoaders();
  }

  /**
   * Defines a checkpoint on the progress line. When requested a progress status of a tag, only bytes loaded
   * after the tag is created will be taken into account.
   * 
   * @param maxPriority The inclusive limit of what assets should be included.
   */
  createTagToPriority(maxPriority: number): ProgressTag {
    const getLoaders = () => this.allLoaders.filter(f => f.asset.priority <= maxPriority);
    const initialLoaded = getLoaders().reduce((ac, i) => ac + i.loaded, 0);

    return { getLoaders, initialLoaded };
  }

  /**
   * Defines a checkpoint on the progress line. When requested a progress status of a tag, only bytes loaded
   * after the tag is created will be taken into account.
   * 
   * @param chunkNames An array of chunk names.
   */
  createTagToChunks(...chunkNames: string[]): ProgressTag {
    const getLoaders = () => this.allLoaders.filter(f => chunkNames.indexOf(f.asset.chunkName) >= 0);
    const initialLoaded = getLoaders().reduce((ac, i) => ac + i.loaded, 0);

    return { getLoaders, initialLoaded };
  }

  /**
   * Get a promise to be resolved when all assets with priority lower than or equals to `maxPriority` are
   * loaded.
   * @param maxPriority The inclusive limit of what assets should be included on progress.
   */
  getPriorityPromise(maxPriority: number): Promise<AssetData[]> {
    const loaders = this.allLoaders.filter(f => f.asset.priority <= maxPriority);

    const promises = loaders.map(l => l.asset.promise);

    return Promise.all(promises);
  }

  /**
   * Get a promise to be resolved when all assets belonging to the chunks with names included on `chunkName`
   * are loaded.
   * 
   * @param chunkNames An array of chunk names.
   */
  getChuckPromise(...chunkNames: string[]): Promise<AssetData[]> {
    const loaders = this.allLoaders.filter(f => chunkNames.indexOf(f.asset.chunkName) >= 0);

    const promises = loaders.map(l => l.asset.promise);

    return Promise.all(promises);
  }
}