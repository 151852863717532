import React, { useEffect, useRef } from 'react';
import { audioControl } from '../controls/audioControl';

export default function AudioPlayer() {
  const audioRef = useRef<HTMLAudioElement>(null);

  useEffect(() => {
    audioControl.setPlayer(audioRef.current);
  }, []);

  return (
    <audio ref={audioRef} style={{ display: 'none' }} />
  );
}