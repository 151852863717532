import { useState, useEffect } from 'react';
import { AssetData } from '../data/assetData';
import { CeHelper } from './ceHelper';

export function useAssetData(asset: AssetData) {
  const [source, setSource] = useState<string>(null);

  async function load() {
    if (!asset) return;
    if (!asset.promise) return;

    await asset.promise;

    setSource(asset.data);
  }

  useEffect(() => {
    load();
  }, [asset]);

  return source;
}

export interface Size {
  width: number;
  height: number;
}

/**
 * Get the size of the screen.
 */
export function useScreenSize(): Size {
  const [width, setWidth] = useState<number>(window.innerWidth);
  const [height, setHeight] = useState<number>(window.innerHeight);

  function onResize() {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  }

  useEffect(() => {
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    }
  }, []);

  return { width, height };
}

function calculateCeContainerSize(currentScreenSize: Size) {
  const { width: sWidth, height: shHeight } = currentScreenSize;

  const width = (sWidth / shHeight) > CeHelper.widescreenRatio
    ? shHeight * CeHelper.widescreenRatio
    : sWidth;

  const height = (sWidth / shHeight) < CeHelper.widescreenRatio
    ? sWidth / CeHelper.widescreenRatio
    : shHeight;

  return { width, height };
};


/**
 * Get the size of CrewEngine Container.
 */
export function useCeContainerSize(): Size {
  const screenSize = useScreenSize();

  const [size, setSize] = useState(calculateCeContainerSize(screenSize));

  useEffect(() => {
    setSize(calculateCeContainerSize(screenSize));
  }, [screenSize.width, screenSize.height]);

  return size;
}

function calculateScale(baseScreenHeight: number, targetHeight: number, currentHeight: number): number {
  const newHeight = Math.floor(targetHeight * currentHeight / baseScreenHeight);

  return newHeight / targetHeight;
}

/**
 * Get scale that should be applied to a target which has to maintain a fixed size
 * relative to CrewEngine container.
 * @param baseScreenHeight
 * The height of the CrewEngine container used as base height on calculation. Also
 * this parameter determines the maximum height that the target should related to,
 * meaning if the target will not grow if the container height exceds this value.
 * @param targetHeight
 * The exactly height of the target when the CrewEngine container has
 * baseScreenHeight.
 */
export function useHudScale(baseScreenHeight: number, targetHeight: number) {
  const { height } = useCeContainerSize();
  const [scale, setScale] = useState(calculateScale(baseScreenHeight, targetHeight, height));

  useEffect(() => {
    setScale(calculateScale(baseScreenHeight, targetHeight, height));
  }, [baseScreenHeight, targetHeight, height]);

  return scale;
}


/**
 * Use a default value for a prop.
 * @param propValue The value of a property.
 * @param defaultValue The default value if the property is invalid.
 */
export function useDefault<T>(propValue: T, defaultValue: T) {
  const [value, setValue] = useState<T>(propValue || defaultValue);

  useEffect(() => {
    setValue(propValue || defaultValue);
  }, [propValue, defaultValue]);

  return value;
}