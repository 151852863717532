import { CeHelper } from "../helpers/ceHelper";
import { Notifier } from "../helpers/notifier";
import { GenericFunc } from "../helpers/types";

export enum LayerVisibility {
  Render = 1 << 0,
  Interface = 1 << 1,
  Hud = 1 << 2,
  NoRender = Interface | Hud,
  NoInterface = Render | Hud,
  NoHud = Render | Interface,
  All = Render | Interface | Hud
}

export enum LayerOrder {
  RIH = 1 << 3,
  RHI = 1 << 4,
  IRH = 1 << 5,
  IHR = 1 << 6,
  HRI = 1 << 7,
  HIR = 1 << 8
}

export type LayerZIndex = 10 | 20 | 30;

export enum LayerMode {
  Default = LayerVisibility.All | LayerOrder.RIH,
  Cinematic = LayerVisibility.Render | LayerOrder.RIH,
  CinematicHud = LayerVisibility.NoInterface | LayerOrder.RIH
}

export interface LayerControlMap {
  onRenderVisibilityChange: (control: LayerControl, oldValue: boolean, newValue: boolean) => void;
  onInterfaceVisibilityChange: (control: LayerControl, oldValue: boolean, newValue: boolean) => void;
  onHudVisibilityChange: (control: LayerControl, oldValue: boolean, newValue: boolean) => void;
  onRenderZIndexChange: (control: LayerControl, oldValue: number, newValue: number) => void;
  onInterfaceZIndexChange: (control: LayerControl, oldValue: number, newValue: number) => void;
  onHudZIndexChange: (control: LayerControl, oldValue: number, newValue: number) => void;
  onVisibilityChange: (control: LayerControl) => void;
  onOrderChange: (control: LayerControl) => void;
  onModeChange: (control: LayerControl) => void;
  onChange: (control: LayerControl) => void;
}

export default class LayerControl extends Notifier<LayerControlMap> {
  private _renderVisibility: boolean = true;
  private _interfaceVisibility: boolean = true;
  private _hudVisibility: boolean = true;
  private _renderZIndex: LayerZIndex = 10;
  private _interfaceZIndex: LayerZIndex = 20;
  private _hudZIndex: LayerZIndex = 30;
  private _visibility: LayerVisibility;
  private _order: LayerOrder;
  private _mode: LayerMode;

  constructor() {
    super();
  }

  get renderVisibility() { return this._renderVisibility; }
  get interfaceVisibility() { return this._interfaceVisibility; }
  get hudVisibility() { return this._hudVisibility; }
  get renderZIndex() { return this._renderZIndex; }
  get interfaceZIndex() { return this._interfaceZIndex; }
  get hudZIndex() { return this._hudZIndex; }
  get visibility() { return this._visibility; }
  get order() { return this._order; }
  get mode() { return this._mode; }

  private setProp(propName: '_renderVisibility' | '_interfaceVisibility' | '_hudVisibility' | '_renderZIndex' | '_interfaceZIndex' | '_hudZIndex', value: boolean | LayerZIndex): boolean {
    if (this[propName] === value) return false;

    const old = this[propName];
    (<any>this)[propName] = value;

    const eventName = `on${propName.substr(1, 1).toUpperCase()}${propName.substr(2)}Change`;

    this.dispatchEvent(<any>eventName, this, old, value);

    return true;
  }

  private setVisibilityCallback(visibility: LayerVisibility, onChange: GenericFunc = undefined) {
    let hasChanges = false;
    hasChanges = this.setProp('_renderVisibility', CeHelper.hasFlag(visibility, LayerVisibility.Render)) || hasChanges;
    hasChanges = this.setProp('_interfaceVisibility', CeHelper.hasFlag(visibility, LayerVisibility.Interface)) || hasChanges;
    hasChanges = this.setProp('_hudVisibility', CeHelper.hasFlag(visibility, LayerVisibility.Hud)) || hasChanges;

    if (hasChanges) {
      this.dispatchEvent('onVisibilityChange', this);
      if (onChange) onChange();
    }
  }

  private setOrderCallback(order: LayerOrder, onChange: GenericFunc = undefined) {
    const values: { r: LayerZIndex, i: LayerZIndex, h: LayerZIndex } = { r: 10, i: 20, h: 30 };
    const enumName = LayerOrder[CeHelper.removeInvalidFlags(order, LayerOrder)].toLowerCase();

    values[enumName[0]] = 10;
    values[enumName[1]] = 20;
    values[enumName[2]] = 30;

    let hasChanges = false;
    hasChanges = this.setProp('_renderZIndex', values.r) || hasChanges;
    hasChanges = this.setProp('_interfaceZIndex', values.i) || hasChanges;
    hasChanges = this.setProp('_hudZIndex', values.h) || hasChanges;

    if (hasChanges) {
      this.dispatchEvent('onOrderChange', this);
      if (onChange) onChange();
    }
  }

  setVisibility(visibility: LayerVisibility) {
    this.setVisibilityCallback(<number>visibility, () => {
      this.dispatchEvent('onModeChange', this);
      this.dispatchEvent('onChange', this);
    });
  }

  setOrder(order: LayerOrder) {
    this.setOrderCallback(<number>order, () => {
      this.dispatchEvent('onModeChange', this);
      this.dispatchEvent('onChange', this);
    });
  }

  setMode(mode: LayerMode) {
    let wasCalled = false;

    const onChange = () => {
      if (wasCalled) return;

      wasCalled = true;
      this.dispatchEvent('onModeChange', this);
      this.dispatchEvent('onChange', this);
    };

    this.setVisibilityCallback(<number>mode, onChange);
    this.setOrderCallback(<number>mode, onChange);
  }
}

export const layerControl = new LayerControl();