import React, { ReactNode, useEffect, useState } from 'react';
import { AssetData } from '../data/assetData';

export interface ScreenProps {
  rootClass?: string;
  containerClass?: string;
  background?: AssetData;
  visible?: boolean;
  children?: ReactNode;
}

export function Screen(props: ScreenProps) {
  const [bg, setBg] = useState<string>(undefined);

  async function loadBg() {
    if (!props.background) return;
    await props.background.promise;
    setBg(props.background.data);
  }

  useEffect(() => {
    loadBg();

  }, [props]);

  return (
    <div className={`layer ${props.rootClass || ''}`} style={{ display: props.visible === false ? 'none' : 'block' }}>
      {bg && <img className="layer" src={bg} />}
      <div className={`layer ${props.containerClass || ''}`}>
        {props.children}
      </div>
    </div>
  )
}
