import { AssetData } from "../data/assetData";
import { createMeshData } from "../data/factory";
import { CeHelper } from "../helpers/ceHelper";
import { Notifier } from "../helpers/notifier";
import FrameControl from "./frameControl";
import { layerControl } from './layerControl';

export interface VideoControlMap {
  ended: (id: BufferId) => void;
  assetChanged: (video: AssetData) => void;
}

export type BufferId = 0 | 1;
type BufferDictionary<T> = { [key in BufferId]: T };

export default class VideoControl extends Notifier<VideoControlMap>{
  private currentAsset: AssetData = null;
  private currentBufferId = 1 as BufferId;
  private get backBufferId() { return this.currentBufferId === 0 ? 1 : 0; }
  private readonly buffers = {} as BufferDictionary<HTMLVideoElement>;
  private readonly readys = {} as BufferDictionary<Promise<void>>;
  private readonly frameControl = new FrameControl(this);

  private getNextBuffer() {
    return this.currentBufferId === 0 ? 1 : 0;
  }

  private updateZIndex(sendToFront: BufferId) {
    const backZIndex = layerControl.renderZIndex + 1;
    const frontZIndex = layerControl.renderZIndex + 2;

    this.buffers[0].style.zIndex = `${sendToFront === 0 ? frontZIndex : backZIndex}`;
    this.buffers[1].style.zIndex = `${sendToFront === 1 ? frontZIndex : backZIndex}`;
  }

  addBuffer(id: BufferId, buffer: HTMLVideoElement) {
    this.buffers[id] = buffer;

    buffer.addEventListener('ended', () => {
      this.dispatchEvent('ended', id);
    });
  }

  async setVideo(video: AssetData) {
    console.log('VideoControl.setVideo => 01 => ', { currentId: this.currentAsset?.assetId, videoId: video.assetId });

    if (this.currentAsset?.assetId === video.assetId) return;

    const nextBufferId = this.getNextBuffer();

    console.log('VideoControl.setVideo => 02 => ', { nextBufferId });

    return this.readys[nextBufferId] = new Promise((resolve, reject) => {
      console.log('VideoControl.setVideo => 03 => ', { buffer: this.buffers[nextBufferId] });

      this.buffers[nextBufferId].addEventListener('canplay', (...args: any) => {
        console.log('VideoControl.setVideo => 03.1 on canplay => ', { args, nextBufferId });
        this.updateZIndex(nextBufferId);
        resolve();
      }, { once: true });

      this.buffers[nextBufferId].addEventListener('loadedmetadata', (...args: any) => {
        console.log('VideoControl.setVideo => 03.3 on canplay => ', { args, nextBufferId });
        this.updateZIndex(nextBufferId);
        resolve();
      }, { once: true });

      this.buffers[nextBufferId].addEventListener('error', (error: ErrorEvent) => {
        console.log('VideoControl.setVideo => 03.2 on error => ', { error, message: error.message, nextBufferId, buffer: this.buffers[nextBufferId] });
        reject('There is some problem on setting up the asset of video processor');
      }, { once: true });

      this.buffers[nextBufferId].addEventListener('invalid', (...args: any) => {
        console.log('VideoControl.setVideo => 03.3 on invalid => ', { args, nextBufferId, buffer: this.buffers[nextBufferId] });
      });

      try {
        this.buffers[nextBufferId].src = video.data;
        this.currentBufferId = nextBufferId;
        this.currentAsset = video;
        console.log('VideoControl.setVideo => 04 => ', { src: this.buffers[nextBufferId].src, data: video.data, currentBufferId: this.currentBufferId, currentAsset: this.currentAsset });
      }
      catch (ex) {
        console.log('VideoControl.setVideo => 05 on exception => ', { ex, src: this.buffers[nextBufferId].src, data: video.data, currentBufferId: this.currentBufferId, currentAsset: this.currentAsset });
      }
    });
  }

  async play() {
    console.log('VideoControl.play => 01 ', { promise: this.readys[this.currentBufferId] });

    await this.readys[this.currentBufferId];

    console.log('VideoControl.play => 02 ', { promise: this.readys[this.currentBufferId] });

    this.buffers[this.currentBufferId].play();

    console.log('VideoControl.play => 03 => after play');

    this.buffers[this.backBufferId].pause();

    console.log('VideoControl.play => 03 => after pause');
  }

  async setTime(time: number) {
    console.log('VideoControl.setTime => 01 ', { time, promise: this.readys[this.currentBufferId] });

    await this.readys[this.currentBufferId];

    console.log('VideoControl.setTime => 02 ', { time, promise: this.readys[this.currentBufferId] });

    await new Promise(resolve => {
      this.buffers[this.currentBufferId].addEventListener('seeked', () => {
        console.log('VideoControl.setTime => 03 => on seeked ');
        resolve();
      }, { once: true });

      console.log('VideoControl.setTime => 04 => before setting currentTime ');

      try {
        this.buffers[this.currentBufferId].currentTime = time;

        console.log('VideoControl.setTime => 05 => currentTime setted ', { currentTime: this.buffers[this.currentBufferId].currentTime, time });
      }
      catch (ex) {
        console.log('VideoControl.setTime => 06 => error on currentTime ', { ex });
      }
    });
  }

  async setTimeAlpha(alpha: number) {
    console.log('VideoControl.setTimeAlpha => 01 ', { alpha, promise: this.readys[this.currentBufferId] });

    await this.readys[this.currentBufferId];

    console.log('VideoControl.setTimeAlpha => 02 ', { alpha, promise: this.readys[this.currentBufferId], duration: this.buffers[this.currentBufferId].duration });

    await this.setTime(CeHelper.lerp(alpha, 0, this.buffers[this.currentBufferId].duration));

    console.log('VideoControl.setTimeAlpha => 03 after setTime ', {});
  }

  async autoPlayToEnd(video: AssetData, timeAlpha: number = 0): Promise<void> {
    console.log('VideoControl.autoPlayToEnd [01] => ', { video, timeAlpha });

    await this.setVideo(video);

    console.log('VideoControl.autoPlayToEnd [02] => ', { video, timeAlpha });

    return new Promise(async resolve => {
      console.log('VideoControl.autoPlayToEnd [03] => ', { video, timeAlpha });

      this.addEventListenerOnce('ended', (...args: any) => {
        console.log('VideoControl.autoPlayToEnd [04] => on ended ', { args });
        resolve();
      });

      try {
        console.log('VideoControl.autoPlayToEnd [05.1] => before setTimeAlpha ', {});
        await this.setTimeAlpha(timeAlpha);
        console.log('VideoControl.autoPlayToEnd [05.2] => after setTimeAlpha ', {});
      }
      catch (ex) {
        console.log('VideoControl.autoPlayToEnd [05.3] => setTimeAlpha exception ', { ex });
      }


      console.log('VideoControl.autoPlayToEnd [06.1] => before play ', {});

      try {
        console.log('VideoControl.autoPlayToEnd [06.1] => before play ', {});
        await this.play();
        console.log('VideoControl.autoPlayToEnd [06.1] => after play ', {});
      }
      catch (ex) {
        console.log('VideoControl.autoPlayToEnd [06.1] => play exception ', { ex });
      }
    });
  }

  async autoPauseOn(video: AssetData, timeAlpha: number = 0): Promise<void> {
    await this.setVideo(video);
    await this.setTimeAlpha(timeAlpha);
    await this.pause();
  }

  async pause() {
    await this.readys[this.currentBufferId];

    this.buffers[this.currentBufferId].pause();
  }

  /**
   * Set a specific frame and force pause on video.
   * @param alpha Alpha value to a linear interpolation on video duration.
   * @param trusted If true the frame will be shown, if false frame can be replaced for others if `setFrame` was called before.
   */
  async setFrame(alpha: number, trusted: boolean = false) {
    await this.readys[this.currentBufferId];

    await this.frameControl.push(alpha, this.currentAsset, this.buffers[this.currentBufferId], trusted);
  }
}

export const videoControl = new VideoControl();