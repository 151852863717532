import { Notifier } from "../helpers/notifier";

export interface FullscreenControlMap {
  onFullscreenChanged: (control: FullscreenControl, fullscreenOn: boolean) => void;
}

export default class FullscreenControl extends Notifier<FullscreenControlMap> {
  private fullscreenOn: boolean;
  private container: HTMLElement;

  constructor() {
    super();
  }

  setContainer(element: HTMLElement) {
    this.container = element;
  }

  async enterFullscreen() {
    await this.container.requestFullscreen({ navigationUI: 'show' });
    this.fullscreenOn = true;
    this.dispatchEvent('onFullscreenChanged', this, this.fullscreenOn);
  }

  async exitFullscreen() {
    await document.exitFullscreen();
    this.fullscreenOn = false;
    this.dispatchEvent('onFullscreenChanged', this, this.fullscreenOn);
  }
}

export const fullscreenControl = new FullscreenControl();