import React, { ReactElement } from 'react';
import { AssetData } from '../data/assetData';

export interface FullscreenHudElementProps {
  key: string;
  highlight?: AssetData;
  children?: ReactElement<SVGElement>;
  onClick?: () => void;
}

export function FullscreenHudElement(props: FullscreenHudElementProps) {
  return (props.children);
}

/*





export interface FullscreenHudElementType {
  (props: FullscreenHudElementProps): ReactElement<any, any>;
}

export const FullscreenHudElement: FullscreenHudElementType = (props) => {
  return (
    <div>ok</div>
  );
};

*/