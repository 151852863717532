let assetId = 1;

export type AssetType = 'image' | 'video' | 'frameSequence' | 'mesh' | 'audio';

/**
 * Contains information about an asset.
 */
export class AssetData {
  /**
   * Get asset id.
   */
  readonly assetId: number;

  /**
   * Get the data url where to find the asset on server.
   */
  readonly dataUrl: string;

  /**
   * Get a estimated size of the asset in bytes.
   */
  readonly estimatedSize: number;

  /**
   * Get the type of the asset
   */
  readonly type: AssetType;

  /**
   * Get the asset mime type.
   */
  readonly mime: string;

  /**
   * Get the name of the chunk to which this asset belongs to.
   */
  readonly chunkName: string;

  /**
   * Get the priority of the asset, assets with lower values are loaded first.
   */
  readonly priority: number;

  /**
   * If this asset is already loaded true otherwise false.
   */
  loaded: boolean = false;

  private _data: string | null = null;
  private _promise: Promise<AssetData>;

  /**
   * Created a container to hold an asset data.
   * @param dataUrl The URL to this asset on server.
   * @param estimatedSize An estimation in bytes about the asset size.
   * @param type The type of the asset.
   * @param mime The asset mime type.
   * @param chunkName The name of the chunk to which this asset belongs to.
   * @param priority The priority of the asset, assets with lower values are loaded first.
   */
  constructor(dataUrl: string, estimatedSize: number, type: AssetType, mime: string, chunkName: string, priority: number) {
    this.assetId = assetId++;
    this.dataUrl = dataUrl;
    this.estimatedSize = estimatedSize;
    this.type = type;
    this.mime = mime;
    this.chunkName = chunkName;
    this.priority = priority;
    this.loaded = false;

    this._promise = new Promise(resolve => {
      this.setData = (data: string) => {
        this._data = data;
        this.loaded = true;

        resolve(this);
      };
    });
  }

  /**
   * Get the binary string serialized data of this asset.
   */
  get data() { return this._data; }

  /**
   * Get a promise of where this asset will be loaded.
   */
  get promise() { return this._promise; }

  /**
   * Set the data of this asset.
   * @param data The string data of this asset.
   */
  setData(data: string) {
    this._data = data;

    throw new Error('NotImplementedException');
  }
}
