import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { layerControl } from '../controls/layerControl';
import { Renderer } from './renderer';
import '../styles.scss';
import AudioPlayer from './audioPlayer';
import { fullscreenControl } from '../controls/fullscreenControl';

export interface CeContainerProps {
  background: string;
  children: ReactNode;
  hud?: ReactNode;
}

interface LocalStyle {
  zIndex: number;
  display: 'none' | 'block';
}

interface Styles {
  render: LocalStyle;
  interface: LocalStyle;
  hud: LocalStyle;
}

function makeLocalStyle(display: boolean = false, zIndex: number = 0): LocalStyle {
  return { display: display ? 'block' : 'none', zIndex };
}

function makeStylesFromControl(): Styles {
  return {
    render: makeLocalStyle(layerControl.renderVisibility, layerControl.renderZIndex),
    interface: makeLocalStyle(layerControl.interfaceVisibility, layerControl.interfaceZIndex),
    hud: makeLocalStyle(layerControl.hudVisibility, layerControl.hudZIndex)
  };
}

export function CeContainer({ children, background, hud }: CeContainerProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const [styles, setStyles] = useState(makeStylesFromControl());

  function onModeChange() {
    console.log('mode was changed');
    setStyles(makeStylesFromControl());
  }

  useEffect(() => {
    layerControl.addEventListener('onModeChange', onModeChange);
    fullscreenControl.setContainer(containerRef.current);
  }, []);

  return (
    <div className={`ce-container ${background}`} ref={containerRef}>
      <AudioPlayer />
      <div className="render-area">
        <Renderer {...styles.render} />
        <div className="layer" style={styles.interface}>
          {children}
        </div>
        <div className="layer hud" style={styles.hud}>
          {hud}
        </div>
      </div>
    </div>
  );
}