import { AssetData } from './assetData';

/**
 * A container to hold a sequence of frames.
 */
export class FrameSequenceData extends AssetData {
  /**
   * Get the exact number of frames in the entire video.
   */
  public readonly frameCount: number;

  /**
   * Get the number of frames per second.
   */
  public readonly framePerSecond: number;

  /**
   * Created a container to hold a sequence of frames.
   * 
   * @param dataUrl The URL to this asset on server.
   * @param estimatedSize An estimation in bytes about the asset size.
   * @param chunkName The name of the chunk to which this asset belongs to.
   * @param priority The priority of the asset, assets with lower values are loaded first.
   */
  constructor(dataUrl: string, estimatedSize: number, chunkName: string, frameCount: number, framePerSecond: number, priority: number = 0) {
    super(dataUrl, estimatedSize, 'frameSequence', 'video/mp4', chunkName, priority);

    this.frameCount = frameCount;
    this.framePerSecond = framePerSecond;
  }
}
