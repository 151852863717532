import React from 'react';
import { AssetData } from '../data/assetData';
import { useAssetData } from '../helpers/hooks';

export type HudButtonSize = 'xs' | 'sm' | 'md' | 'lg';

export interface HudButtonProps {
  icon: AssetData;
  size: HudButtonSize;
  onClick?: () => void;
}

export function HudButton(props: HudButtonProps) {
  const source = useAssetData(props.icon);

  return (
    <img className={`hud-button ${props.size} ${props.onClick ? 'clicable' : ''}`} src={source} onClick={props.onClick} />
  );
}