import React, { useEffect, useRef, useState } from 'react';
import { videoControl } from '../controls/videoControl';
import { CeHelper } from '../helpers/ceHelper';

export interface RendererProps {
  zIndex?: number;
  display?: 'none' | 'block';
}

export function Renderer(props: RendererProps) {
  const buff0Ref = useRef<HTMLVideoElement>(null);
  const buff1Ref = useRef<HTMLVideoElement>(null);
  const [zIndex, setZIndex] = useState<number>();
  const [display, setDisplay] = useState<string>();

  useEffect(() => {
    videoControl.addBuffer(0, buff0Ref.current);
    videoControl.addBuffer(1, buff1Ref.current);
  }, []);

  useEffect(() => {
    setZIndex(Number(props.zIndex) || 0);
    setDisplay(CeHelper.checkOnList(props.display, ['none', 'block'], 'none'));
  }, [props.zIndex, props.display]);

  return (
    <div className="layer" style={{ zIndex, display }}>
      <video ref={buff0Ref} className="layer buffer-0" playsInline={true} />
      <video ref={buff1Ref} className="layer buffer-1" playsInline={true} />
    </div>
  )
}